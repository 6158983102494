<template>
  <v-row>
    <v-col cols="12" md="6" sm="12">
      <h1>รายการถูกรางวัล</h1>
      <h3>งวดที่ dd/mm/yyyy</h3>
      <v-card
        elevation="2"
        class="pa-10"
        style="background-color: #00739c; color: white"
      >
        <v-row align="center" justify="center" no-gutters>
          <h2 style="color: white; font-weight: 800">ข้อมูลผู้ซื้อ</h2>
          <v-row>
            <v-col cols="12" md="12" sm="12"><b>ชื่อ-สกุล :</b> sdsdsdsd</v-col>
            <v-col cols="12" md="12" sm="12"
              ><b>หมายเลขโทรศัพท์ :</b> sdsdsdsdsd</v-col
            >
            <v-col cols="12" md="12" sm="12"><b>เฟสบุ๊ค :</b> sdsdsdsdsd</v-col>
            <v-col cols="12" md="12" sm="12"><b>ไลน์ :</b> sdsdsdsdsd</v-col>
          </v-row>
          <v-row><v-divider /></v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6"
              ><span>ยินดีด้วย คุณถูกรางวัล</span></v-col
            >
            <v-col cols="12" md="6" sm="6"><span>จำนวน 36 ใบ</span></v-col>
            <v-col cols="12" md="6" sm="6"><span>รวมเป็นเงิน</span></v-col>
            <v-col cols="12" md="6" sm="6"><span>76000 บาท</span></v-col>
            <v-col cols="12" md="6" sm="6"
              ><span>ค่าบริการขึ้นรางวัล 2%</span></v-col
            >
            <v-col cols="12" md="6" sm="6"><span>1520 บาท</span></v-col>
          </v-row>
          <v-row><v-divider /></v-row>
          <v-row align="center" justify="center">
            <h2 style="color: white; font-weight: 800">ติดต่อขอรับรางวัล</h2>
            <v-col cols="10" md="10" sm="10">
              <v-btn block @click="winprice()" color="primary" dark
                >ขึ้นรางวัลกับ Ready lottery</v-btn
              >
            </v-col>
            <v-col cols="10" md="10" sm="10">
              <v-btn block @click="getBySelf()"
                >รับลอตเตอรี่ที่ถูกรางวัลด้วยตัวเอง</v-btn
              >
            </v-col>
          </v-row>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" md="6" sm="12">
      <h1>รายการลอตเตอรี่ที่ถูกรางวัล</h1>
      <h3>ตัวแทน Ready Lotterry</h3>
      <v-card
        elevation="2"
        class="pa-10"
        style="background-color: #00739c; color: white"
      >
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="6">
            <span>รางวัล เลขท้าย 2 ตัว</span>
            <span>รางวัลละ 2,000 บาท</span>
          </v-col>
          <v-col cols="6">
            <v-card elevation="2" class="text-center" height="100px">
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <span>หมายเลข</span>
                </v-col>
                <v-col cols="12">
                  <h2 style="color: black; font-weight: 800">12</h2>
                </v-col>
              </v-row>
            </v-card></v-col
          >
        </v-row>
        <v-row>
          <span>จำนวน 24 ใบ</span>
          <span>รวมเป็นเงิน</span>
          <span>48,000 บาท</span>
        </v-row>
        <v-row align="center" justify="center">
          <v-btn><span>ดูสลากที่ถูกรางวัล</span></v-btn>
        </v-row>
      </v-card>
    </v-col>
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialogWinprize" persistent max-width="600px">
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Open Dialog
            </v-btn>
          </template> -->
          <v-card>
            <v-card-title>
              <h2 style="color: #00739c; font-weight: 800">
                ขึ้นรางวัลกับ Ready Lottery
              </h2>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <span>กรุณากรอกข้อมูลบัญชีที่ต้องการให้โอนเงินรางวัล</span>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      :items="[
                        'ธนาคารกสิกร',
                        'ธนาคารกรุงไทย',
                        'ธนาคารไทยพานิชย์',
                      ]"
                      label="กรุณาเลือกธนาคาร*"
                      required
                    ></v-select>
                    <!-- <v-text-field label="ธนาคาร*" required></v-text-field> -->
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="ชื่อบัญชี*" required></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="เลขที่บัญชี*" required></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="dialogWinprize = false">
                <v-icon>mdi-cancel</v-icon>ปิดหน้าต่าง</v-btn
              >
              <v-btn color="#00739c" dark @click="dialogWinprize = false">
                ยืนยัน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialogGetBySelf" persistent max-width="600px">
          <v-card class="pa-6">
            <v-container>
              <v-card-title>
                <h2 style="color: #00739c; font-weight: 800">
                  รับลอตเตอรี่ที่ถูกรางวัลด้วยตัวเอง
                </h2>
              </v-card-title>
              <v-row justify="center" align="center">
                <v-col cols="12">
                  <h3 style="color: orange; font-weight: 800">
                    รับลอตเตอรี่ที่ถูกรางวัลด้วยตัวเอง
                  </h3>
                </v-col>
              </v-row>
              <v-row style="border: 1px">
                <v-col cols="12">
                  <span>
                    เนื่องจากสถานการณ์ โควิท-19 ทาง Ready Lottery
                    ยังไม่สามารถให้บริการในส่วนนี้ได้
                  </span>
                </v-col>
                <v-col cols="12">
                  <span> หากมีข้อสงสัย ติดต่อ..... </span>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialogGetBySelf = false">
                  <v-icon>mdi-cancel</v-icon>ปิดหน้าต่าง</v-btn
                >
                <v-btn color="#00739c" dark @click="dialogGetBySelf = false">
                  ยืนยัน
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialogWinprize: false,
      dialogGetBySelf: false,
    };
  },
  methods: {
    winprice() {
      this.dialogWinprize = true;
    },
    getBySelf() {
      this.dialogGetBySelf = true;
    },
  },
};
</script>

<style scoped></style>
